@import "../../base.scss";
.home-container {
  position: relative;
  
  .image-container img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .title {
      text-transform: uppercase;
    }
    .title,
    .description,
    .innerTitle,
    .buttons-container {
      padding: 3px 200px 32px 100px;
    }
    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: center;
      .contact-btn {
        margin-right: 10px;
        text-decoration: none;
        padding: 15px 35px;
        cursor: pointer;
        border: 1px solid;
        &.gray {
          color: #ffffff;
          border-color: #ffffff;
        }
        &.white {
          color: #181e24;
          border-color: #181e24;
        }
      }
    }
  }
}
.tabs-side-text-container {
  font-size: 24px;
  transform-origin: 100% 100%;
  transform: rotate(-90deg) translateX(50%);
  position: absolute;
  right: 10px;
  top: 50%;
  font-family: $font-family-playFair;
  &::after,
  &::before {
    content: "";
    height: 1px;
    width: 250px;
    position: absolute;
    top: 50%;
    background-color: #ffffff;
  }
  &::after {
    left: 100%;
    transform: translate(-50%) translateX(70%);
  }
  &::before {
    left: 0;
    transform: translate(-50%) translateX(-70%);
  }
}
.tabs-container-mobile {
  h3 {
    font-size: 22px;
    align-self: flex-start;
    padding: 20px;
  }
  .title {
    margin: 15px;
    text-align: center;
    font-size: 20px;
  }
  .image-container {
    max-width: 100%;
    height: auto;
    display: inline-block;
    &.border {
      border: 1px solid black;
      border-radius: 15px;
      padding: 20px;
    }
  }
  .info-container {
    .description {
      padding: 15px;
    }
    .buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      padding: 15px;
      text-align: center;
      .contact-btn {
        margin-bottom: 30px;
        text-decoration: none;
        padding: 15px 35px;
        cursor: pointer;
        border: 1px solid;
        color: #ffffff;
        border-color: #ffffff;
      }
    }
  }
}
.horizontal-text-container {
  padding: 20px;
  width: 100%;
  margin-top: -7%;
  display: block;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
}
.horizontal-text-container > p {
  position: relative;
  display: inline-block;
}

.horizontal-text-container > p:before {
  right: 100%;
  margin-right: 10px;
}

.horizontal-text-container > p:after {
  left: 100%;
  margin-left: 10px;
}
.innerTitle{
  font-size:32px;
  margin-top:-7%;
  margin-bottom:-2%;
}


@media only screen and (max-width: 1600px) {
  .innerTitle{
    font-size:29px;
  }
}

@media only screen and (max-width: 1500px) {
  .innerTitle{
    font-size:27px;
  }
}