.container {
  height: 80%;
  width: 100%;
  margin: 16px;
  background-color: #606060;
  color: #ffffff;
  .carousel-content {
    height: 100%;
    width: 1050px;
    display: flex;
    img {
      height: auto;
      max-width: 50%;
    }
    .info {
      width: 100%;
      padding: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      h6 {
        text-transform: uppercase;
      }
      .list {
        max-height: 600px;
        overflow: auto;
      }
      .list-item {
        position: relative;
        padding-left: 25px;
        padding-bottom: 2em;
        line-height: normal;
        overflow: auto;
      }
      .list-item::after {
        content: url("/bullet-white.png");
        position: absolute;
        left: 1px;
        top: 0;
      }
      .list-item::before {
        content: "";
        position: absolute;
        left: 4px;
        height: 100%;
        width: 1px;
        border: 0.5px solid white;
      }
      .list-item:first-child::before {
        top: 0px;
      }
      .list-item:last-child::before {
        height: 22px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .container {
    margin: 0;
    height: 46.5%;
    margin-left: -8%;
    .carousel-content {
      width: auto;
      height: 100%;
      display: block;
      h6 {
        font-size: 20px;
      }
      img{
        max-width: 100%;
      }
      .list {
        max-height: none !important;
        margin-top: 20px;
      }
    }
  }
}
