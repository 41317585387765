@import "../../base.scss";
.benefits-component-container {
  background-color: #a0a0a2;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: 22px;
    align-self: flex-start;
    padding: 20px;
  }
  .benefit-container {
    .title-container {
      background-color: #ffffff;
      color: black;
      padding: 30px;
      position: relative;
      display: flex;
      flex-direction: column;
      .benefit-title {
        padding: 3px;
        text-align: center;
        text-transform: uppercase;
      }
      .index {
        position: absolute;
        top: 25%;
        left: 48%;
        opacity: 0.3;
        font-size: 35px;
      }
    }
    .description-container {
      padding: 30px 20px;
    }
  }
}

.side-text-container {
  padding: 20px;
  margin-top: -8%;
  width: 100%;
  display: block;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  font-family: $font-family-playFair;
}
.side-text-container > p {
  position: relative;
  display: inline-block;
}

.side-text-container > p:before {
  right: 90%;
  margin-right: 10px;
}

.side-text-container > p:after {
  left: 90%;
  margin-left: 10px;
}
