@import "../../base.scss";

.location-header{
  font-family: $font-family-regular;
  font-size:20px;
}

.locations-container {
  padding-top: 10px;
  background-color: #a0a0a2;
  color: white;
  align-items: center;
  justify-content: center;
 
  .helper-text {
    margin: 0 10px;
    font-size:18px;
    margin-left: 3%;
    margin-top:3%;
    display: block;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
  }
  .helper-text > p {
    font-size: 14px;
    position: relative;
    display: inline-block;
  }

  .helper-text > p:before {
    right: 100%;
    margin-right: 10px;
  }
  .helper-text > p:after {
    left: 100%;
    margin-left: 10px;
  }
  .location-info {
    padding: 10px;
  }
  .map-container {
    border-top: 1px solid white;
    text-align: center;
    img {
      padding: 5px;
    }
  }
  .building-container {
    text-align: center;
    img {
      max-width: 100%;
      height: auto;
    }
    img:first-of-type {
      padding: 5px;
    }
  }
  .location-image {
    display: block;
  }
}
