@import "../../base.scss";

.home-container {
  height: 100vh;
  position: relative;
  .image-container img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    .title,
    .innerTitle {
      text-transform: uppercase;
    }
    .title,
    .description,
    .innerTitle,
    .buttons-container,
    .key-advantages {
      padding: 3px 100px 52px 70px;
    }

    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: center;
      .contact-btn {
        margin-right: 10px;
        text-decoration: none;
        padding: 15px 35px;
        cursor: pointer;
        border: 1px solid;
        &.gray {
          color: #ffffff;
          border-color: #ffffff;
        }
        &.white {
          color: #181e24;
          border-color: #181e24;
        }
      }
    }
  }
}

.listt,
.listt-item {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 1.2em;
  width: 90%;
  margin-left:5%;
}
.listt-item.left::after {
  content: url("/bullet.png");
  position: absolute;
  left: -26px;
  top: 0;
}
.listt-item.right::after {
  content: url("/bullet.png");
  position: absolute;
  left: -26px;
  top: 0;
}
.listt-item::before {
  content: "";
  position: absolute;
  left: -22px;
  height: 100%;
  width: 0.5px;
}
.listt-item.left::before {
  border: 0.5px solid lightgray;
}
.listt-item.right::before {
  border: 0.5px solid lightgray;
}
.listt-item:first-child::before {
  top: 0px;
}
.listt-item:last-child::before {
  height: 22px;
}

.description {
  margin-top: -3%;
}

.side-text-container-right {
  font-size: 24px;
  transform-origin: 100% 100%;
  transform: rotate(-90deg) translateX(50%);
  position: absolute;
  right: 10px;
  font-family: $font-family-playFair;
  &::after,
  &::before {
    content: "";
    height: 1px;
    width: 250px;
    position: absolute;
    top: 50%;
  }
  &::after {
    left: 100%;
    transform: translate(-50%) translateX(70%);
  }
  &::before {
    left: 0;
    transform: translate(-50%) translateX(-70%);
  }
}
.side-text-container-right.gray::before,
.side-text-container-right.gray::after {
  background-color: #ffffff;
}

.side-text-container-right.white::before,
.side-text-container-right.white::after {
  background-color: gray;
}
.side-text-container-left {
  font-size: 24px;
  position: absolute;
  left: 5px;
  transform: translate(-40%) rotate(270deg);
  font-family: $font-family-playFair;
  &::after,
  &::before {
    content: "";
    height: 1px;
    width: 250px;
    position: absolute;
    top: 50%;
  }
  &::after {
    left: 100%;
    transform: translate(-50%) translateX(70%);
  }
  &::before {
    left: 0;
    transform: translate(-50%) translateX(-70%);
  }
}
.side-text-container-left.gray::before,
.side-text-container-left.gray::after {
  background-color: #ffffff;
}

.side-text-container-left.white::before,
.side-text-container-left.white::after {
  background-color: gray;
}

.side-text {
  font-family: $font-family-playFair;
}

.innerTitle {
  font-size: 32px;
  margin-top: -4%;
}
@media only screen and (max-width: 1600px) {
  .innerTitle {
    font-size: 29px;
  }
}

@media only screen and (max-width: 1500px) {
  .innerTitle {
    font-size: 27px;
  }
}
