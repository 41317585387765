.gallery-component {
  // height: 100vh;
  .gallery-component__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
    margin-bottom: -3%;
    div {
      padding-right: 63px;
    }
  }
  h2 {
    padding: 20px;
    margin-left: 70px;
  }
  .options-button {
    font-family: "Montserrat-Regular";
    outline: none;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 30px;
    padding-right: 25px;
    
    &.active {
      font-weight: bold;
    }
  }
  .gallery-container {
    display: flex;
    padding: 50px;
    justify-content: center;
    background-color: #ffffff;
    height: 817px;
    .gallery {
      height: 90%;
      width: 80%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .gallery-thumbs {
      padding: 0px 20px;
      &::-webkit-scrollbar {
        scrollbar-width: thin;
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px gray;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: black;
        border-radius: 2px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .gallery-component {
    .gallery-component__heading {
      display: inline;
      p {
        padding: 20px;
      }
    }
    h4 {
      font-size: 22px;
      margin: 0;
    }
    .gallery-container {
      height: 100%;
      padding: 0;
      flex-direction: column;
      .gallery {
        width: 100%;
        height: 100%;
      }
      .gallery-thumbs {
        white-space: nowrap;
        overflow: hidden;
        overflow-x: auto;
        padding: 4px;
        .image-container {
          display: inline;
          width: 63px;
          height: 63px;
          img {
            width: 63px;
            height: 63px;
          }
        }
      }
    }
  }
}
