@import "../../base.scss";

.home-container {
  height: 110vh;
  overflow: hidden;
  position: relative;
  .image-container img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    .title,.innerTitle {
      text-transform: uppercase;
      padding: 3px 191px 52px 75px;
    }
    .list,
    .buttons-container {
      line-height: 1.4em;
      padding: 3px 191px 52px 100px;
    }
    .list-item {
      position: relative;
      margin-bottom: 0;
      padding-bottom: 1.2em;
    }
    .list-item.left::after {
      content: url("/bullet-white.png");
      position: absolute;
      left: -26px;
      top: 0;
    }
    .list-item.right::after {
      content: url("/bullet.png");
      position: absolute;
      left: -26px;
      top: 0;
    }
    .list-item.right.darker::after{
      content: url("/bullet-white.png");
      position: absolute;
      left: -26px;
      top: 0;
    }
    .list-item.right.darker::before{
      border: 0.5px solid lightgray;
    }
    .list-item::before {
      content: "";
      position: absolute;
      left: -22px;
      height: 100%;
      width: 0.5px;
    }
    .list-item.left::before {
      border: 0.5px solid lightgray;
    }
    .list-item.right::before {
      border: 0.5px solid #a2a2a2;
    }
    .list-item:first-child::before {
      top: 0px;
    }
    .list-item:last-child::before {
      height: 22px;
    }
    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: center;
      .contact-btn {
        margin-right: 10px;
        text-decoration: none;
        padding: 15px 35px;
        cursor: pointer;
        border: 1px solid;
        &.left {
          color: #ffffff;
          border-color: #ffffff;
        }
        &.right {
          color: #181e24;
          border-color: #181e24;
        }
      }
    }
  }
}

.side-text-container.left {
  font-size: 24px;
  position: absolute;
  left: 0;
  transform: translate(-40%) rotate(270deg);
  font-family: $font-family-playFair;
  &::after,
  &::before {
    content: "";
    height: 1px;
    width: 250px;
    background-color: #a2a2a2;
    position: absolute;
    top: 50%;
  }
  &::after {
    left: 100%;
    transform: translate(-50%) translateX(70%);
  }
  &::before {
    left: 0;
    transform: translate(-50%) translateX(-70%);
  }
}

.side-text-container.right {
  font-size: 24px;
  transform-origin: 100% 100%;
  transform: rotate(-90deg) translateX(50%);
  font-family: $font-family-playFair;
  position: absolute;
  right: 10px;
  &::after,
  &::before {
    content: "";
    height: 1px;
    width: 250px;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
  }
  &::after {
    left: 100%;
    transform: translate(-50%) translateX(70%);
  }
  &::before {
    left: 0;
    transform: translate(-50%) translateX(-70%);
  }
}

.innerTitle{
  font-size:32px;
  margin-top:-6%;
}
@media only screen and (max-width: 1600px) {
  .innerTitle{
    font-size:29px;
  }
}

@media only screen and (max-width: 1500px) {
  .innerTitle{
    font-size:27px;
  }
}
