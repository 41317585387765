*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

body {
  background-color: #000000;
  color: #ffffff;
}
#root {
  min-height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .flex {
  display: flex;
  display:-webkit-box; 
  display:-moz-box;  
  display:-ms-flexbox; 
  display:-webkit-flex;
}
.animation {
  -webkit-transition:all 0.5s ease-in-out;
  -moz-transition:all 0.5s ease-in-out;
  -ms-transition:all 0.5s ease-in-out;
  -o-transition:all 0.5s ease-in-out;
  transition:all 0.5s ease-in-out;
} 

.video-bg {position:relative; top:0; left:0; width:100%; min-height:100%; }
.video-bg > div {
    position: absolute; z-index:0; top:50%; left:0; width:100% !important; height:100% !important; 
    transform: translateY(-50%);
}
.video-bg > div video,
#background-video {
    position: absolute;
    top: 50%; left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%; min-height: 100%; 
    width: auto !important; 
    height: auto !important;
    z-index: -1000; 
    overflow: hidden;
}
*/
body {
  font-family: sans-serif;
  background: #ffffff;
}

#root {
  flex: 1;
  height: 100%;
}
/* 

.dashboard-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
}

@import url("https://fonts.googleapis.com/css?family=Permanent+Marker");
@import url("https://fonts.googleapis.com/css?family=PT+Sans"); */
