@import "../../base.scss";

.carousel-component-container {
  background: url("/Homepage-section-6.png") no-repeat center center;
  // height: 100vh;
  background-size: cover;
  h4 {
    padding: 20px;
    margin-left: 40px;
    font-size: 45px;
    color: #ffffff;
  }
  .side-text-carousel-component {
    color: #ffffff;
    font-size: 24px;
    position: absolute;
    left: 0;
    transform: translate(-40%) rotate(270deg);
    font-family: $font-family-playFair;
    &::after,
    &::before {
      content: "";
      height: 1px;
      width: 250px;
      background-color: white;
      position: absolute;
      top: 50%;
    }
    &::after {
      left: 100%;
      transform: translate(-50%) translateX(70%);
    }
    &::before {
      left: 0;
      transform: translate(-50%) translateX(-70%);
    }
  }
  .carousel-component {
    width: 100%;
    overflow: auto;
    display: flex;
    margin: 0px 0px 10px 50px;
    height:100vh;
    &.mobile{
      height: 350vh;

    }
  }
  .mobile-carousel-component{
    width: 100%;
      margin: 0px 0px 10px 50px;
      height:300vh;
  }
  .mobile-carousel-component-bigger{
    width: 100%;
      margin: 0px 0px 10px 50px;
      height:100%;
  }
}

::-webkit-scrollbar {
  scrollbar-width: thin;
  width: 2px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px lightgray;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 2px;
}

.side-text{
  font-family: $font-family-playFair;
 }
 

 @media only screen and (max-width: 1600px) {
   .innerTitle{
     font-size:29px;
   }
 }
 
 @media only screen and (max-width: 1500px) {
   .innerTitle{
     font-size:27px;
   }
 }
@media only screen and (max-width: 900px) {
  .carousel-component-container {
    background: #a0a0a2;
    justify-content: center;
    height: 100%;
    h4 {
      font-size: 22px;
      margin: 0;
    }
    .carousel-component {
      flex-direction: column;
      margin: 0;
    }
    .side-text-carousel-component {
      padding: 25px;
      margin: 0 10px;
      display: block;
      text-align: center;
      white-space: nowrap;
      position: relative;
      transform: none;
      &::before,
      &::after {
        top: 59%;
      }
      &::after {
        left: 87%;
      }
      &::before {
        left: 20px;
      }
    }
    .side-text-carousel-component > p {
      font-size: 14px;
      position: relative;
      display: inline-block;
    }
  }
}
