@import "../../base.scss";

.benefits-container {
  height: 100vh;
  display: flex;
  position: relative;
  .benefits-indexes-container {
    margin: 20px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    .benefit {
      // max-width: 228px;
      // margin-left: 50px;
      align-self: center;
      position: relative;
      cursor: pointer;
      &.active {
        font-weight: bold;
        border-right: 1px solid black;
        border-left: 1px solid black;
      }
      .index {
        font-family: "PlayfairDisplay-Regular";
        font-size: 150px;
        color: #a0a0a2;
        position: absolute;
        top: -84px;
        left: 40px;
        opacity: 0.3;
        z-index: -1;
      }
      .benefit-title {
        text-align: center;
        text-transform: uppercase;
        padding-right: 32px;
        font-size: 15px;
        z-index: 9;
      }
    }
  }
  .info-container {
    height: 100%;
    background-color: #a0a0a2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      text-transform: uppercase;
    }
    .title,
    .description,
    .buttons-container {
      color: #ffffff;
      padding: 3px 277px 52px 100px;
    }
    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: center;
      .contact-btn {
        margin-right: 10px;
        text-decoration: none;
        padding: 15px 35px;
        cursor: pointer;
        border: 1px solid #ffffff;
        color: #ffffff;
      }
    }
  }
}
.side-text-container {
  font-size: 24px;
  transform-origin: 100% 100%;
  color: #ffffff;
  transform: rotate(-90deg) translateX(50%);
  position: absolute;
  right: 10px;
  font-family: "PlayfairDisplay-Regular";
  &::after,
  &::before {
    content: "";
    height: 1px;
    width: 250px;
    position: absolute;
    top: 50%;
    background-color: #ffffff;
    font-family: $font-family-playFair;
  }
  &::after {
    left: 100%;
    transform: translate(-50%) translateX(70%);
  }
  &::before {
    left: 0;
    transform: translate(-50%) translateX(-70%);
  }
}
