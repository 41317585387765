.footer-container {
  padding: 20px;
  color: white;
  background-color: #a0a0a2;
  &.display-none {
    display: none;
  }
  // #bold {
  //   font-family: "Montserrat-Bold";
  // }
  // #light {
  //   font-family: "M-Light";
  // }
  #small {
    margin: 10px 0;
  }
  span {
    font-size: 18px;
    cursor: pointer;
  }
  p {
    margin-top: 20px;
    // font-family: "Gotham-Lighter";
    cursor: pointer;
  }
  .footer-inner-container {
    width: 100%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    .footer-column:first-child{
      margin-left:6%;
    }
    .footer-column {
      max-width: 25%;
      width: 25%;
      span {
        cursor: pointer;
      }
      &.large {
        text-align: unset;
        max-width: 27%;
        width: 27%;
        .flex-container {
          display: flex;
          .social-icon {
            width: 18px;
            height: 18px;
            margin: 15px 30px 15px 0;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            &.facebook {
              background-image: url("/icons/facebook-icon.svg");
            }
            &.instagram {
              background-image: url("/icons/instagram-icon.svg");
            }
            &.linkedin {
              background-image: url("/icons/linkedin-icon.svg");
            }
            &.youtube {
              background-image: url("/icons/youtube.svg");
            }
          }
        }
        .input-wrapper {
          position: relative;
          border-bottom: solid 1px white;
          width: 90%;
          margin-top: 10px;
          input {
            background-color: transparent;
            color: white;
            height: 40px;
            width: 100%;
            font-size: 18px;
            border: none;
          }
          .input-arrow-icon {
            position: absolute;
            background-image: url("/icons/input-arrow.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            right: 5px;
            top: 23%;
            cursor: pointer;
            &.sent {
              background-image: url("/icons/checkmark-icon.svg");
            }
          }
        }
      }
    }
  }
  .footer-bottom-container {
    border-top: solid 1px rgba(255, 255, 255, 0.63);
    .footer-bottom-inner-container {
      display: flex;
      justify-content: space-between;
      width: 20%;
      margin: 0 auto;
      p {
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .footer-container {
    .footer-inner-container {
      flex-direction: column;
      .footer-column {
        max-width: 100%;
        width: 100%;
        margin-bottom: 40px;
        text-align: unset;
        span {
          font-size: 20px;
        }
        p {
          font-size: 18px;
        }
        &.large {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .footer-bottom-container {
      .footer-bottom-inner-container {
        width: 90%;
        flex-direction: column;
      }
    }
  }
}
