@import "../../base.scss";

.locations-container {
  background-color: #a0a0a2;
  height: 100vh;
  position: relative;
  color:white;
  .image-container {
    height: 100vh;
  }
  .locations-info-container {
    .locations-tabs-container {
      padding-left: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: 60px;
      }
    }
    .options-container {
      justify-content: space-evenly;
      align-items: center;
      height: 100vh;
      border-left: 0.5px solid gray;
      img {
        padding: 25px;
        cursor: pointer;
      }
    }
  }
  .side-text-container-locations {
    font-size: 24px;
    position: absolute;
    left: 0;
    transform: translate(-40%) rotate(270deg);
    bottom: 400px;
    font-family: $font-family-playFair;
    &::after,
    &::before {
      content: "";
      height: 1px;
      width: 250px;
      position: absolute;
      top: 50%;
      background-color: gray;
      font-family: $font-family-playFair;
    }
    &::after {
      left: 100%;
      transform: translate(-50%) translateX(70%);
    }
    &::before {
      left: 0;
      transform: translate(-50%) translateX(-70%);
    }
  }
  // .side-text-container-left.gray > p:before,
  // .side-text-container-left.gray > p:after {
  //   background-color: white;
  // }

  // .side-text-container-left.white > p:before,
  // .side-text-container-left.white > p:after {
  //   background-color: gray;
  // }
}
.innerTitle{
  font-size:32px;
  margin-top:-4%;
}
@media only screen and (max-width: 1600px) {
  .innerTitle{
    font-size:29px;
  }
}

@media only screen and (max-width: 1500px) {
  .innerTitle{
    font-size:27px;
  }
}
