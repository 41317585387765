@import "../../base.scss";

.description li {
  list-style-type: circle;
}

.home-container {
  position: relative;
  .image {
    width: 100%;
    height: 100vh;
    display: block;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    .title {
      text-transform: uppercase;
    }
    .title,
    .buttons-container,
    .innerTitle,
    .key-advantages {
      padding: 3px 30px 27px 80px;
    }
    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: center;
      .contact-btn {
        margin-right: 10px;
        text-decoration: none;
        padding: 15px 35px;
        cursor: pointer;
        border: 1px solid;
        color: #ffffff;
        border-color: #ffffff;
      }
    }
  }
}
.side-text-container-left {
  font-size: 24px;
  position: absolute;
  left: 0;
  transform: translate(-40%) rotate(270deg);
  font-family: $font-family-playFair;
  &::after,
  &::before {
    content: "";
    height: 1px;
    width: 250px;
    position: absolute;
    background-color: gray;
    top: 50%;
  }
  &::after {
    left: 100%;
    transform: translate(-50%) translateX(70%);
  }
  &::before {
    left: 0;
    transform: translate(-50%) translateX(-70%);
  }
}
.mobile-environments {
  .horizontal-text-container {
    padding: 20px;
    width: 100%;
    margin-top:-7%;
    display: block;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
  }
  .horizontal-text-container > p {
    position: relative;
    display: inline-block;
  }
  .horizontal-text-container > p:before {
    right: 100%;
    margin-right: 10px;
  }

  .horizontal-text-container > p:after {
    left: 100%;
    margin-left: 10px;
  }
  h4 {
    font-size: 22px;
    align-self: flex-start;
  }
  .mobile-environments-container {
    padding-top: 20px;
    .image {
      width: 100%;
      height: auto;
      display: block;
    }
    .environment-title {
      font-size: 18px;
    }
  }
  h4,
  .environment-title,
  .description {
    padding: 20px;
  }
}
.innerTitle{
  font-size:32px;
  margin-top:-4%;
}
@media only screen and (max-width: 1600px) {
  .innerTitle{
    font-size:29px;
  }
}

@media only screen and (max-width: 1500px) {
  .innerTitle{
    font-size:27px;
  }
}

@media only screen and (max-width: 1280px) {
 .home-container{
  .image{
    height: 111vh;
  }
 }
}