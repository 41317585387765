@import "../../../base.scss";

.home-container {
  .title {
    margin: 15px;
    font-size: 22px;
  }
  .image-container {
    max-width: 100%;
    height: auto;
    display: inline-block;
    &.border {
      border: 1px solid black;
      border-radius: 15px;
      padding: 20px;
    }
  }
  .info-container {
    .description {
      padding: 15px;
    }
    .buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      padding: 15px;
      text-align: center;
      .contact-btn {
        margin-bottom: 30px;
        text-decoration: none;
        padding: 15px 35px;
        cursor: pointer;
        border: 1px solid;
        &.gray {
          color: #ffffff;
          border-color: #ffffff;
        }
        &.white {
          color: #181e24;
          border-color: #181e24;
        }
      }
    }
  }
  .side-text-container {
    padding: 20px;
    width: 90%;
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
  }
  .side-text-container > p {
    position: relative;
    display: inline-block;
  }

  .side-text-container > p:before,
  .side-text-container > p:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background-color: black;
    font-family: $font-family-playFair;
  }
  .side-text-container > p:before {
    right: 100%;
    margin-right: 10px;
  }

  .side-text-container > p:after {
    left: 100%;
    margin-left: 10px;
  }
}
