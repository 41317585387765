@import "../../base.scss";

.inquiry-container {
  height: 100%;
  overflow: hidden;
  align-items: center;
  position: relative;
  .image-container img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.side-text-container-inquiry-left {
  font-size: 24px;
  position: absolute;
  left: 0;
  transform: translate(-40%) rotate(270deg);
  font-family: $font-family-playFair;

  &::after,
  &::before {
    content: "";
    height: 1px;
    width: 250px;
    // background-color: black;
    position: absolute;
    top: 50%;
  }
  &::after {
    left: 100%;
    transform: translate(-50%) translateX(70%);
  }
  &::before {
    left: 0;
    transform: translate(-50%) translateX(-70%);
  }
}

.side-text-container-inquiry-right {
  font-size: 24px;
  transform-origin: 100% 100%;
  transform: rotate(-90deg) translateX(50%);
  position: absolute;
  right: 10px;
  font-family: $font-family-playFair;
  &::after,
  &::before {
    content: "";
    height: 1px;
    width: 250px;
    // background-color: gray;
    position: absolute;
    top: 50%;
  }
  &::after {
    left: 100%;
    transform: translate(-50%) translateX(70%);
  }
  &::before {
    left: 0;
    transform: translate(-50%) translateX(-70%);
  }
}


@media only screen and (max-width: 900px) {
  .inquiry-container {
    justify-content: center;
    .side-text-container-inquiry {
      padding: 20px;
      width: 90%;
      display: block;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
    }
    .side-text-container-inquiry > p {
      position: relative;
      display: inline-block;
    }

    .side-text-container-inquiry > p:before,
    .side-text-container-inquiry > p:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background-color: white;
    }

    .side-text-container-inquiry > p:before {
      right: 100%;
      margin-right: 10px;
    }

    .side-text-container-inquiry > p:after {
      left: 100%;
      margin-left: 10px;
    }
  }

  .info-container-inquiry {
    padding-left: 10px;
  }
}
