.article-info-container {
  padding: 0 50px;
  height: 1000px;
  width: 50%;
  h4 {
    font-size: 30px;
  }
  .text-container {
    height: 100%;
    overflow-y: scroll;
    font-size: 22px;
  }
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}
