.articles-container {
  height: 100vh;
  position: relative;
  .featured-article-container {
    .info-container {
      color: #ffffff;
      position: absolute;
      bottom: 0px;
      width: 50%;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 40px;
      h4 {
        font-size: 30px;
        width: 60%;
      }
      h4::after {
        content: "";
        height: 1px;
        width: 40%;
        right: 20px;
        position: absolute;
        background-color: #ffffff;
      }
      p {
        font-size: 22px;
      }
      .button-container {
        margin-top: 40px;
        .contact-btn {
          margin-right: 10px;
          text-decoration: none;
          padding: 15px 35px;
          cursor: pointer;
          border: 1px solid #ffffff;
          color: #ffffff;
        }
      }
    }
    .article-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -100;
    }
  }
  .more-articles-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: auto;
    padding: 5px 0 0 30px;
    .article {
      height: 183px;
      width: 90%;
      padding: 30px;
      display: flex;
      align-items: center;
      border-top: 1px solid #a0a0a2;
      .description {
        padding: 15px 0px;
      }
      img {
        width: auto;
        height: 100%;
        object-fit: cover;
      }
      &:last-of-type {
        border-bottom: 1px solid #a0a0a2;
      }
      .article-short-info {
        padding-left: 20px;
      }
      .button-container {
        margin-top: 10px;
        .contact-btn {
          text-decoration: none;
          padding: 5px 20px;
          cursor: pointer;
          border: 1px solid black;
          color: black;
        }
      }
    }
  }
}

.side-text {
  font-size: 24px;
  transform-origin: 100% 100%;
  transform: rotate(-90deg) translateX(50%);
  position: absolute;
  top: 50%;
  right: 10px;
  &::after,
  &::before {
    content: "";
    height: 1px;
    width: 250px;
    position: absolute;
    top: 50%;
    background-color: #a0a0a2;
  }
  &::after {
    left: 100%;
    transform: translate(-50%) translateX(70%);
  }
  &::before {
    left: 0;
    transform: translate(-50%) translateX(-70%);
  }
}
