@font-face {
  font-family: "Montserrat-Black";
  src: url("fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-BlackItalic";
  src: url("fonts/Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Italic";
  src: url("fonts/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PlayfairDisplay-Regular";
  src: url("fonts/PlayfairDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
// @font-face {
//   font-family: "Montserrat-Bold";
//   src: url("fonts/Montserrat-Bold.eot");
//   src: url("fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
//     url("fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg"),
//     url("fonts/Montserrat-Bold.ttf") format("truetype"),
//     url("fonts/Montserrat-Bold.woff") format("woff"),
//     url("fonts/Montserrat-Bold.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Montserrat-Black";
//   src: url("fonts/Montserrat-Black.eot");
//   src: url("fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"),
//     url("fonts/Montserrat-Black.svg#Montserrat-Black") format("svg"),
//     url("fonts/Montserrat-Black.ttf") format("truetype"),
//     url("fonts/Montserrat-Black.woff") format("woff"),
//     url("fonts/Montserrat-Black.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "icomoon";
//   src: url("fonts/icomoon.eot?qtvcbr");
//   src: url("fonts/icomoon.eot?qtvcbr#iefix") format("embedded-opentype"),
//     url("fonts/icomoon.ttf?qtvcbr") format("truetype"),
//     url("fonts/icomoon.woff?qtvcbr") format("woff"),
//     url("fonts/icomoon.svg?qtvcbr#icomoon") format("svg");
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
