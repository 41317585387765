@import "../../base.scss";

.testimonials-grid-container {
  height: 100vh;
  padding: 30px;
  overflow: hidden;
  position: relative;
  .image-container {
    border: 1px solid black;
    padding: 50px;
    border-radius: 30px;
    text-align: center;
    img {
      max-width: 100%;
      height: 250px;
    }
  }
  .info-container-testimonials {
    display: flex;
    flex-direction: column;
    .description{
      width:115%;
    }
    .title,
    .description,
    .company-name,
    .buttons-container {
      padding: 3px 277px 52px 100px;
    }
    .title {
      text-transform: uppercase;
    }
    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: center;
      .contact-btn {
        margin-right: 10px;
        text-decoration: none;
        padding: 15px 35px;
        cursor: pointer;
        border: 1px solid;
        white-space: nowrap;
        &.left {
          color: #ffffff;
          border-color: #ffffff;
        }
        &.right {
          color: #181e24;
          border-color: #181e24;
        }
      }
    }
  }
}

.side-text-container-testimonials {
  font-size: 24px;
  position: absolute;
  left: 2px;
  transform: translate(-40%) rotate(270deg);
  font-family: $font-family-playFair;
  &::after,
  &::before {
    content: "";
    height: 1px;
    width: 250px;
    background-color: gray;
    position: absolute;
    top: 50%;
  }
  &::after {
    left: 100%;
    transform: translate(-50%) translateX(70%);
  }
  &::before {
    left: 0;
    transform: translate(-50%) translateX(-70%);
  }
}

.all-companies-container {
  padding: 30px;
  div {
    justify-content: space-evenly;
    align-items: center;
    .company-grid-item {
      padding: 10px;
      &.active {
        border: 1px solid black;
        border-radius: 10px;
      }
    }
  }
}
.company-logo {
  max-width: 100%;
  height: auto;
  display: inline-block;
  cursor: pointer;
}
.description{
  margin-top:-4%;
}
@media only screen and (max-width: 1500px) {
  .description{
    font-size: 13px;
  }
}
@media only screen and (max-width: 1300px) {
  .description{
    font-size: 12px;
  }
}